<template>
  <v-card>
    <v-card-title class="text-h6"
      >{{ updatingItem.code_name }} -
      {{ $t("labels.basket_create") }}</v-card-title
    >

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model.number="quantity"
            class="c-input-small"
            :label="$t('labels.quantity')"
            type="number"
            dense
            outlined
            clearable
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn color="success darken-1" text @click="create">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CreateBasket",
  props: {
    updatingItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    quantity: null,
    isLoading: false,
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async create() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await this.downloadExcelFile(
          `/warehouse/v1/create-basket`,
          {
            id_warehouse: this.updatingItem.id,
            quantity: this.quantity,
          },
          `100x25_stamp.pdf`
        );
        this.$vToastify.success(this.$t("messages.create_success"));
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
